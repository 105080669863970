.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15%;
    margin-bottom: 10px;
  }
  
  .navButton {
    background-color: #0095f6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .navButton:hover {
    background-color: #007bb5; /* Darker shade for hover effect */
  }
  