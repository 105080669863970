#Education {
    text-align: center;
    padding: 20px;
  }
  
  .abt {
    font-size: 2em;
    margin-bottom: 20px;
  }
  .EducationImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px; 
  }
  
  .EducationImg {
    max-width: 100%;
    height: auto;
    margin: 10px;
    border-radius: 0.5rem; 
    margin: 10px 0;
    object-fit: cover; 
  }
  
  span {
    font-size: 1.2em; 
    margin-bottom: 10px; 
  }
  