#Home {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #9493fc;
    color: white; */
    /* font-weight: bold;
    font-family: 'Times New Roman', Times, serif; */
}

.h1 {
    font-size: x-large;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    letter-spacing: 0.5;
}

.desccription {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    
}

.subdiv {
    width: 50%;
}

.profile {
    width: 15%;
    height: 18%;
    border-radius: 50%;
    margin: 20px 0;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.num {
    margin: 10px 20px;
    text-align: center;
}

.text {
    font-size: 14px;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 300;
}

.text2 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

.buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    color: white;
    background-color: blue;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: darkblue;
}

@media screen and (max-width: 767px) {
    .num {
        margin: 10px 20px;
    }

    .text {
        font-size: 1rem;
    }

    .text2 {
        font-size: 1rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: bold;
    }

    .subdiv {
        width: 100%;
    }

    .profile {
        width: 35%;
        height: 115px;
        border-radius: 50%;
        margin: 20px 0;
    }

    /* .h1 {
        text-align: left;
    } */
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .num {
        margin: 10px 20px;
    }

    .text {
        font-size: 22px;
    }

    .text2 {
        font-size: 22px;
    }

    .subdiv {
        width: 100%;
    }

    .profile {
        width: 30%;
        height: 20rem;
        border-radius: 50%;
        margin: 20px 0;
    }

    /* .h1 {
        text-align: left;
    } */
}