/* #work {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}
.abt {
    text-align: center;
} */
/* .WorkImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 65rem;
    flex-wrap: wrap;
} */
#Work {
    text-align: center;
    padding: 20px;
  }
  
  .abt {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .work-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .WorkImg {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }
  