#About1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7f3f8;
}

#About2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f3f9;
}

#About3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf3ec;
}

#About4 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #faf1f5;
}

#About5 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf3ec;
}
#About {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee9bf;
}

#PersonalityAbout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c1cdcd;
}

#resumecontainerAbout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eedfcc;
}
.aboutsubdiv {
  width: 50%;
}

.AboutTextAndImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.AboutTextAndImgs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  column-gap: 10px;
}

.AboutImg {
  width: 50%;
  height: auto;
}

.AboutImg2 {
  width: 49%;
  height: 15rem;
}

.AboutImg1 {
  width: 100%;
  height: 20rem;
}

span {
  display: block;
  margin-bottom: 10px;
}

.abt {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .aboutsubdiv {
    width: 100%;
  }

  .AboutImg {
    width: 100%;
    height: auto;
  }

  .AboutTextAndImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    row-gap: 10px;
  }

  .AboutImg2 {
    width: 100%;
    height: 15rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .aboutsubdiv {
    width: 100%;
  }

  .AboutImg {
    width: 100%;
    height: auto;
  }

  .AboutTextAndImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    row-gap: 10px;
  }

  .AboutImg2 {
    width: 100%;
    height: 25rem;
  }

  .AboutImg1 {
    width: 100%;
    height: 30rem;
  }
}