  #Skill {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skilldiv {
    width: 50%;
    margin-top: 2rem;
  }

  .skillsimgs {
    display: flex;
    padding: 20px;
  }

  .abt {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .SkillImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .SkillImg {
    max-width: 100%;
    height: auto;
    margin: 10px;
    border-radius: 0.5rem;
    margin: 10px 0;
    object-fit: cover;
  }

  @media screen and (max-width: 767px) {
    .skilldiv {
      width: 100%;
    }

    .AboutImg {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1023px) {
    .skilldiv {
      width: 100%;
    }

    .AboutImg {
      width: 100%;
      height: auto;
    }
  }