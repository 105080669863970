.app {
  align-items: center;
  justify-content: center;
  /* background-image: url('../src/assests/bg.jpg');
  object-fit: cover; */
}

.flag {
  width: 40px;
  vertical-align: middle;
}

.resume {
  width: 100%;
  height: 50px;
  border-radius: 1rem;
  background-color: #0095f6;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.resume:hover {
  background-color: #007bb5;
}

.docview {
  display: flex;
  justify-content: center;
}
.resumecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.professional {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
span {
  text-align: center;
  color: black;
  font-weight: bold;
}

/* .resumecontainer {
  text-align: center;
  margin-top: 20px;
} */

.pdf-container {
  /* margin-top: 20px; */
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  border: none;
}

@media screen and (max-width: 767px) { 
  .docview {
    display: flex;
    justify-content: flex-start;
    width: 100vw;
  }

  .reactplayer {
    width: 100%;
  }
}